import { UnleashClient } from "unleash-proxy-client";
import { config } from "./config";

export const unleashKeys = {
  shortMclid: "midastrackr.short-mclid"
};

export function getUnleash(uid: string): Promise<UnleashClient> {
  const unleash = new UnleashClient({
    url: config.unleash.url(),
    clientKey: config.unleash.apiKey(),
    appName: config.unleash.appName(),
    disableRefresh: true,
    disableMetrics: true,
    refreshInterval: 0,
    metricsInterval: 0,
    metricsIntervalInitial: 0,
    context: {
      userId: uid
    }
  });

  return new Promise((res, rej) => {
    unleash.on("ready", () => {
      res(unleash);
    });

    unleash.on("error", (err: any) => {
      rej(err);
    });

    unleash.start();
  });
}

export function getFakeUnleash() {
  return Promise.resolve({
    isEnabled: (_flag: string) => false
  });
}
