const config = {
  api() {
    const defaultApiUrl =
      config.env() === "prod"
        ? "https://api.midastrackr.com/api/"
        : "https://hml-api.midastrackr.com/api/";

    return window.midastrackr.apiUrl || defaultApiUrl;
  },
  env() {
    return window.midastrackr.env || "prod";
  },
  isBot() {
    return new RegExp(
      [
        "google-adwords",
        "google web preview",
        "google-structured-data",
        "appengine-google",
        "feedfetcher-google",
        "adsbot-google",
        "googlebot",
        "facebookexternalhit",
        "bingbot",
        "msnbot",
        "bingpreview",
        "pinterestbot",
        "google-read-aloud",
        "petalbot",
        "ahrefsbot",
        "adidxbot",
        "brightbot",
        "cloudflare-alwaysonline"
      ].join("|"),
      "i"
    ).test(navigator.userAgent);
  },
  unleash: {
    url() {
      return (
        window.midastrackr.unleashUrl ||
        "https://unleash-bbc2ajbserhwegb4.centralus-01.azurewebsites.net/api/frontend"
      );
    },
    apiKey() {
      return (
        window.midastrackr.unleashApiKey ||
        (config.env() === "prod"
          ? "*:production.c6517325a97832482511565aca20be17a176e0fa40d148b0c23e49b3"
          : "*:development.81b4a4175d3ffa8b1bbf20e84b99e3b366245d6323b1dcf3fb5c2288")
      );
    },
    appName() {
      return window.midastrackr.unleashAppName || "midastrackrsnippet";
    }
  }
};

export { config };
